
export default {
    props: {
        form_id: {
            type: String,
            required: true
        }
    },
    mounted() {
        if(window.hbspt) {
            //create dynamic div_id
            this.createForm()
        } else {
            this.loadScript()
        }
    },
    methods:{
        loadScript() {
            const script = document.createElement('script')
            script.src="https://js.hsforms.net/forms/v2.js?pre=1"
            script.defer = true

            document.body.appendChild(script)
            script.addEventListener('load', () => {
                if(window.hbspt) {
                    this.createForm()
                }
            })
        },
        createForm() {
            window.hbspt.forms.create({
                region: "na1",
                portalId: "172251",
                formId: this.form_id,
                target: `#${this.div_id}`
            })
        }
    },
    computed: {
        div_id() {
            return `tfs-form-${this.form_id}`
        }
    }
}
