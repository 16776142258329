
export default {
    data() {
        return {
            intervalTime: 8000,
            reviews: [
                `I’ve been a TrueFire All Access Student for over 20 years. I’ve watched the company grow and expand its catalog of courses and educators. The quality of the courses just keeps getting better. They offer so many styles of music, so there is always something there for everyone.`, 

                `I’ve never seen courses have the quality and name-brand educators for this low price anywhere. Their customer service has always been excellent, and they have gone out of their way to answer any questions and make things right.`,

                `Amazing content. I've done acoustic blues lessons that have helped me develop way faster than I would have thought. Pure quality.`,

                `Many excellent courses to choose from. I like how many courses are structured with each subtopic's overview, performance, and breakdown. TrueFire is probably the closest thing you can get to actual one-on-one instruction. I wouldn’t change a thing!`,

                `Very good and useful courses. I love the format, the package, and the app. Easy to order. The content on the website is very clear. You can get courses almost for everything you need.`,

                `Awesome guitar lessons. Great instructors, too. It's very easy to navigate the website. Very user-friendly. The All Access package is the way to go.`,

                `TrueFire is an amazing resource for anyone who wants to learn guitar. Anyone can find the right path for learning the instrument, from the beginner to the most advanced level. I highly recommend the All Access subscription.`,

                `A huge and constantly growing range of tutorials. A multiverse of topics and genres for guitarists and other musicians. Easy-to-navigate website. A vast community of top-notch instructors. Something for everyone at all levels of interest. TrueFire’s All Access program offers a world of instruction at an amazingly good price.`,

                `The more time I spend at TrueFire, the more respect and gratitude I feel for this excellent company of true artists and content creators.`,

                `It’s an excellent platform for learning guitar. I love how the videos cover every angle so you can see every movement of the player instructor. I’m learning blues right now, and it’s so easy to follow up with every video lesson.`,

                `I was at the intermediate level, looking for some missing bits to help me move forward, when I found TrueFire. TrueFire will take you from beginner to advanced or help you find the missing bits in your playing. There’s something here for everyone. The best guitarists on the planet hang out at TrueFire, waiting to pass on their wealth of knowledge at little cost. TrueFire has excellent course content, and I highly recommend this site.`,

                `I have broadened my musical horizons by taking advantage of the TrueFire All Access subscription to try different styles. All lessons work with my schedule and learning curve. The only negative is when I get started, I don’t want to stop!`,

                `The BEST guitar learning resource on planet earth, period! If you are serious about becoming a better player, look no further! Whatever your style or ability, TrueFire has something for you.`,

                `It seems that TrueFire has always been among the first to take advantage of new technologies as they become available. I could not find better quality tools to propel my learning, coupled with the most likable teachers you can find. I can’t imagine how I could do without TrueFire now; they make learning a pleasurable experience.`,

                `Learning guitar with TrueFire has been exciting and fulfilling. It’s just like the instructor was in person, and the lessons are endless.`,

                `I came to TrueFire after trying several different online guitar courses. I wish I had come here first - this place has all you need. There are a vast number of courses, a fantastic range of instructors, and an excellent interface that lets you learn and practice at your own pace with multiple views plus tabs, including speed and loop controls. There is really a lifetime’s worth of content here.`,

                `There is a vast range of artists available. Each has a fantastic set of courses available. The content is amazing, and it’s great to download and work with on the go.`,

                `I love TrueFire. The courses are well done, and the interface is perfect. Slowing down or speeding up or looping anything to focus on precisely what you need to work on is extremely useful.`,

                `TrueFire has a fantastic array of courses that are well presented and easy to follow. I have bought many of these and will continue to do so. Keep up the good work!`,

                `TrueFire costs a little more than a video streaming service like Netflix, but the value is significantly greater. I've learned many specific skills over my four years as a member, but I also enjoy watching for inspiration.`,

                `TrueFire is a great resource for guitar material no matter what your level of experience. The lessons I have purchased are easy to follow, and the tools are easy to use. I wish something like this was available when I started playing 60 years ago.`,

                `The courses are fantastic! It is like having your teacher sitting with you at any time over a coffee and a guitar, of course. The explanations are spot on, and the demonstrations are accurate. Highly recommended.`,

                `A great selection of courses across a wide range of genres. I am a long-term All Access Student and clearly have benefited a lot from the high quality of teachers and the great infrastructure TrueFire provides. Bravo!`,

                `TrueFire is the 500-pound gorilla in online guitar education for good reasons. They seem to pick educators who are not only excellent, even elite-level players, but who genuinely seem to enjoy teaching.`,

                `The range of options is enormous. I thought I’d stay a jazz player my whole life, but over my years with TrueFire, I’ve broadened my horizons to many other genres. It's addicting (in a good way :-)`,

                `I have tried a few online guitar courses, and TrueFire is by far the best for me. You can’t quite beat the value for money you get with thousands of lessons from unarguably some of the best guitarists in the world.`,

                `Great service all around. The True Fire Website is excellent and easy to navigate. The tools are really useful and don’t get in the way of learning… very important!`,

                `Amazing tool for all guitarists and bass players. Great lessons from beginner to advanced. Has helped me focus on practicing and playing more!`,

                `Excellent service and a brilliant website to learn guitar and better your skills set as a musician.`,

                `So much choice at really good prices. Everything I have purchased has been, in my opinion, brilliant and really useful.`,

                `To me, TrueFire is the best guitar site available. Easy navigation and the overall display of the site are convincing, just as the choice of first-class educators is. The range of courses is staggering, but that is a problem I can live with!`,

                `For me, the best guitar learning solution out there. Great tutors, a broad range of courses, synced tabs, and affordable pricing.`,

                `TrueFire offers a wide range of resources, from high-quality lessons with renowned instructors to an impressive variety of musical styles to explore. Its interactive approach and emphasis on practice make it an essential resource for any serious guitarist looking to refine their technical and artistic skills.`,

                `Super high quality and an enormous variety of lessons. Far and away the best site in its class.`,

                `The All Access package and the service has been just great. I am really enjoying the material. The website is easy to navigate, and the courses are laid out in an easy and logical manner. I highly recommend the use of TrueFire for instrument learning.`,

                `Fantastic site. A variety of styles courses and instructors that elevate the learning process nicely.`,

                `TrueFire’s courses are a great learning resource for musicians at any playing level. Having played blues, rock, and jazz for 40 years, I already understand theory well and can play in several styles. There’s still plenty to learn from other genres at TrueFire. Their courses teach me quickly what would take me months or years to learn on my own.`,

                `Great lesson packages, I’ve been playing guitar for nearly 50 years and really enjoy this format for learning. The TrueFire staff is very helpful and quick to respond to any difficulties experienced by us oldies!`,

                `Short and sweet! Everything with TrueFire is first-rate! I had a couple of issues that were handled with speed and complete courtesy. One of the absolute best companies on the planet!`,

                `An absolutely wonderful platform! The Learning Paths are great, and there are some world-class teachers here! I ended up doing a bunch of jazz courses while studying music theory – something I never thought I’d be able to play, but the courses are all brilliant.`,

                `Best guitar learning platform ever. I’ve made unbelievable progress with TrueFire. There is a great selection of tutors and a variety of courses for all levels – everyone will find what fits best. I especially like the app with synchronized tabs, backing tracks, etc. No more messing around; just get started with learning. Great value for money!`,

                `I am an All Access subscriber and believe it is great value for money. A wealth of courses are available to suit every playing style, ranging from beginner-level courses to very advanced/niche offerings. TrueFire is hard to beat.`,

                `Undoubtedly the best teachers you can find. I haven’t encountered a TrueFire course that doesn’t deserve a 5-star rating. Regardless of the genre you are interested in playing, all of them are covered. The best part is that your teacher will likely be someone you aspire to match in terms of skill. The video quality and tabs are impeccable. What you receive is a comprehensive package of the finest lessons worth every cent invested. I wholeheartedly recommend it.`,

                `Excellent song lesson. Easy to follow. The lessons are very detailed and educational.`,

                `I decided to join TrueFire as an All Access Student about two years ago. What I have explored since this time is just unbelievable. To me, the TrueFire site is a fountain of inspiration to get out of some practice routine traps that every musician taps in to sometimes. It broadened my perspective of other styles and techniques and took me to places I never had expected.
                That's a real ‘TrueFire’ that will burn on and on and on…`,
            ]
        }
    },
    created(){
        setInterval(this.flipCarousel, this.intervalTime)
    },
    methods: {
        flipCarousel(){
            for(let i = 0; i < 3; i++)
                this.reviews.push(this.reviews.shift())
        }
    }
}
