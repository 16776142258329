
	export default {
		props: {
			img: String,
		},
		data() {
			return {
				backgroundImgStyle: "",
			}
		},
		created() {
			this.backgroundImgStyle = `background-image:url('${this.img}');background-position: center;background-size:cover;`
		}
	}
