

	export default {
		props: {
			model: String, // required
			src: String, // required
			title: String,
			size: String
		},
		methods: {
			closeModal() {
				if (!this.model) return false;
				this.$parent[this.model] =  'false';
			}
		}
	}
	
